import styles from './page-footer.module.css';
import staybeautifulLogo from './../../public/staybeautiful-logo.png';
import curliLogo from './../../public/curli-logo.svg';
import bodycareLogo from './../../public/bodycare-logo.png';
import marinamiracleLogo from './../../public/marinamiracle-logo.webp';
import hairlustLogo from './../../public/hairlust-logo.avif';
import blivakkerlogo from './../../public/blivakker-logo.png';
import chicastore from './../../public/chicastore-logo.avif';
import beautymall from './../../public/beautymall-logo.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
export default function PageFooter() {
	return (
		<footer className={styles.footer}>
          	Cherryblossom.no
			<div style={{fontSize: '14px', marginTop: '8px'}}>Produkter fra kjente butikker</div>
			<div className={styles.logoContainer}>
				<a href="https://www.partner-ads.com/no/klikbanner.php?partnerid=30465&bannerid=93543" target="_blank" rel="sponsored nofollow"><LazyLoadImage src={staybeautifulLogo.src} className={styles.logo} alt="Staybeautiful logo" style={{height: '42px'}} /></a>
				<a href="https://www.partner-ads.com/no/klikbanner.php?partnerid=30465&bannerid=109337" target="_blank" rel="sponsored nofollow"><LazyLoadImage src={curliLogo.src} className={styles.logo} alt="Curli logo" style={{height: '42px'}} /></a>
				<a href="https://www.partner-ads.com/no/klikbanner.php?partnerid=30465&bannerid=91630" target="_blank" rel="sponsored nofollow"><LazyLoadImage src={bodycareLogo.src} className={styles.logo} alt="Australian Bodycare logo" style={{height: '42px'}} /></a>
				<a href="https://www.partner-ads.com/no/klikbanner.php?partnerid=30465&bannerid=106992" target="_blank" rel="sponsored nofollow"><LazyLoadImage src={marinamiracleLogo.src} className={styles.logo} alt="MarinaMiracle logo" style={{height: '16px'}} /></a>
				<a href="https://www.partner-ads.com/no/klikbanner.php?partnerid=30465&bannerid=58192" target="_blank" rel="sponsored nofollow"><LazyLoadImage src={hairlustLogo.src} className={styles.logo} alt="Hairlust logo" style={{height: '56px'}} /></a>
				<a href="https://go.blivakker.no/t/t?a=1786465255&as=1925297522&t=2&tk=1" target="_blank" rel="sponsored nofollow"><LazyLoadImage src={blivakkerlogo.src} className={styles.logo} alt="Blivakker logo" style={{height: '42px'}} /></a>
				<a href="https://ion.chicastore.no/t/t?a=1689202641&as=1925297522&t=2&tk=1" target="_blank" rel="sponsored nofollow"><LazyLoadImage src={chicastore.src} className={styles.logo} alt="Chicastore logo" style={{height: '42px'}} /></a>
				<a href="https://pin.beautymall.no/t/t?a=1866875952&as=1925297522&t=2&tk=1" target="_blank" rel="sponsored nofollow"><LazyLoadImage src={beautymall.src} className={styles.logo} alt="Beautymall logo" style={{height: '42px'}} /></a>
			</div>
			<a href="/om-oss" style={{fontSize: '12px', color: "#e5205e"}}>Om Cherryblossom.no</a><br/>
			<span style={{fontSize: '12px'}}>&copy; Copyright {new Date().getFullYear()}</span>
		</footer>
	);
}